/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    h3: "h3",
    br: "br",
    img: "img",
    ul: "ul",
    li: "li",
    h4: "h4"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, StateCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As a leader in ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "hearing aid technology"), " and powered by the world’s most advanced online platform for hearing acoustics, our aim is to offer customers a truly groundbreaking auditory experience. Armed with a fleet of unique, customizable devices, Horizon hearing aids not only meet, but exceed every requirement and expectation of hearing aid wearers."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "horizon-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-hearing-aids",
    "aria-label": "horizon hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " are getting ever smaller, the technology within them is getting ever more brilliant. Sensors that adapt to new hearing situations in milliseconds. Processors that perfectly discern the direction, distance, and speed of various sounds and model them to produce an absolutely natural spatial hearing experience. Our Horizon AX and Horizon X ranges are globally unique hearing systems featuring this pioneering technology to bring you hearing aid devices that cater to your individual needs."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "horizon-go-ax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-go-ax",
    "aria-label": "horizon go ax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Go AX"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon/horizon-hearing-aids-box-black.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The strength of Horizon AX comes to the fore in complex hearing situations with several sound sources and speakers. Using two separate processors, this state-of-the-art technology significantly increases the contrast between speech and background noise. The result: Speech is more easily understandable, and the acoustic environment sounds more real.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "With state-of-the-art lithium-ion technology, simply charge your Horizon Go AX hearing aids overnight in the slim and elegant charging box and enjoy up to 24 hours of crystal-clear hearing.")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "horizon-mini-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-mini-x",
    "aria-label": "horizon mini x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Mini X"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon/trying-mini.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hear everything, see nothing. As one of the smallest hearing aids in the world, the Horizon Mini X disappears completely into the ear while ensuring brilliant sound and supreme comfort.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "A high-performance processor with over one billion computing operations per second ensures crystal-clear speech on the Horizon Mini X  – even in background noise. The big advantage: The chip is so small that it can easily fit into even the smallest of our hearing devices.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "connect-your-hearing-aids-with-the-horizon-app",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connect-your-hearing-aids-with-the-horizon-app",
    "aria-label": "connect your hearing aids with the horizon app permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Connect your hearing aids with the Horizon app"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-hearing-aids-app.png",
    alt: "Horizon App",
    className: "c-md-img"
  })), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing aid and smartphone perfectly connected"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Simple, intuitive user interface"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Stream and make telephone calls directly via the hearing aid"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your cell phone model has Bluetooth you can listen to music or audio-books directly via your hearing systems. With a Horizon you can manage the settings and streaming content discreetly via your iPhone or Android smartphone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With StreamLine TV you can receive the sound of the television directly in your ears. StreamLine Mic is a real help during hands-free telephone calls. Take calls directly via the discreet clip-on microphone."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "horizon-app-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-app-features",
    "aria-label": "horizon app features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon App Features"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With its groundbreaking technology, the Horizon AX hearing aid stands out for its unparalleled features, offering users an immersive auditory experience that combines advanced noise cancellation, personalized sound optimization, and seamless connectivity, setting a new standard in hearing assistance."), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "speech-focus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#speech-focus",
    "aria-label": "speech focus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Speech Focus"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Isolate varying voice tones and pitches"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Automatically soften background noise"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hone in on individual speakers"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Drown out the noise while keeping the sounds that are important. With the Speech Focus feature on the Horizon app, an extra boost of hearing clarity is at your fingertips. All speech frequencies are accentuated at the touch of a button and annoying background noise is filtered out, enabling you to effortlessly hear the person who is speaking to you regardless of the environment you are in."), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "panorama-effect",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#panorama-effect",
    "aria-label": "panorama effect permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Panorama Effect"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A unique 360° hearing experience"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clear and realistic comprehension from every direction"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Full panoramic sound at the touch of a button"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are moments that we wish to enjoy with all our senses. Moments of complete freedom in the great outdoors. Whether hiking, strolling, jogging, hunting, or walking the dog. For such moments, we have developed the unique Panorama Effect. At the touch of a button on your Horizon AX smartphone app, you can easily shift the focus settings on your hearing aids from speech comprehension to a 360° auditory experience that captures the full sound scope of your surroundings."), "\n", React.createElement(_components.h4, {
    className: "c-md-h4",
    id: "relax-mode",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#relax-mode",
    "aria-label": "relax mode permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Relax Mode"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Horizon hearing aids are the first in the world to offer something that many people desire in an ever noisier world: peace and quiet."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Relax at the touch of a button"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Suppress background noise"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Reduce stress and increase concentration"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wind down, gather your thoughts, and boost your overall well-being with Relax Mode. Whether you are getting ready for bed or just need a break during the day, you can use this setting to reduce background noise and decompress by choosing from an array of soothing natural sounds. Switch on the feature to help you simply and effectively switch off."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearcom-hearing-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearcom-hearing-specialist",
    "aria-label": "contact a hearcom hearing specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hear.com hearing specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Embark on your journey to better hearing with ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", where exceptional service meets cutting-edge technology. Our dedicated team collaborates with the top 2% of audiologists in the United States, ensuring that you receive personalized care and expert guidance every step of the way. With over 1200 hearing specialists poised to assist you, we're committed to providing unparalleled customer service and answering all your questions with clarity and compassion. Take the first step towards a life filled with clearer, richer sound—contact hear.com today!"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "Try Horizon AX hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "There are several types of hearing loss and many different cause.",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
